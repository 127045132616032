import * as constand from "../constant";
import { getData, postData } from "../_helpers/api-helper";
import handleResponse from "../_services/handle.service";

export function getUserBatches() {
  return async (dispatch, getState) => {
      const requestOptions = getData();
      const url = `${constand.BACKEND_URL}/api/badges/getUserBatches`;

      try {
          const response = await fetch(url, requestOptions);
          const data = await handleResponse(response, dispatch);
          dispatch({ type: 'FETCH_BADGES_SUCCESS', payload: data });
      } catch (error) {
          console.error("Error fetching badge details:", error);
      }
  };
}