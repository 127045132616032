import * as constand from "../constant";
import { getData, postData } from "../_helpers/api-helper";
import handleResponse from "../_services/handle.service";
import { GET_PAGE_DATA,UPDATE_WARNING_BANNER } from "../utilities";
import { commonService } from "../_services";
require("es6-promise").polyfill();
require("isomorphic-fetch");
var moment = require("moment-timezone");

export function getPageData() {
  return (dispatch, getState) => {
    const requestOptions = getData();

    return fetch(constand.BACKEND_URL + "/api/page/home", requestOptions)
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        /* dispatch({
          type: PRIVACY_PAGE,
          payload: (response.data) ? response.data.warning_text : []
        }); */
        return response;
      });
  };
}

export function getPageData2() {
  return (dispatch, getState) => {
    const requestOptions = getData();

    return fetch(constand.BACKEND_URL + "/api/page/home-2", requestOptions)
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        /* dispatch({
          type: PRIVACY_PAGE,
          payload: (response.data) ? response.data.warning_text : []
        }); */
        return response;
      });
  };
}

export const updateWarningBanner = (warningText) => ({
  type: UPDATE_WARNING_BANNER,
  payload: warningText,
});

/* export function getPageData() {
  return (dispatch, getState) => {
    const requestOptions = getData();
    return fetch(constand.BACKEND_URL + "/api/page/home", requestOptions)
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        dispatch({
          type: GET_PAGE_DATA,
          payload: response.country,
        });
        return response;
      });
  };
} */
