import React from "react";
import { RichText } from "prismic-reactjs";

const ArticleSection = ({ articleSections }) => {
  const items = articleSections.items || [];
  return (
    <div className="container-fluid px-120 gradient-light py-lg-4">
      <div className="d-flex flex-wrap-sm mt-5 articles">
        {items.map((item, index) => (
          <div className="" key={index}>
            <div className="card">
              <img
                src={item.article_image.url}
                alt={item.article_image.alt || "Article Image"}
              />
              <div className="card-body card-body-width px-0 mx-auto">
                <h5 className="card-title text-left font-gbold">
                  <RichText render={item.article_title} />
                </h5>
                <p className="card-text text-left mt-2 font-qregular">
                  <RichText render={item.article_description} />
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArticleSection;
