import React, { Component } from "react";
import Flickity from "flickity";
import "flickity/dist/flickity.min.css"; // Import Flickity CSS
import { RichText } from "prismic-reactjs";
class TestimonialSection extends Component {
  constructor(props) {
    super(props);
    this.testimonialRef = React.createRef();
  }

  componentDidMount() {
    this.flickity = new Flickity(this.testimonialRef.current, {
      wrapAround: false,
      autoPlay: false,
      cellAlign: "center",
      groupCells: 1,
      //contain: true,
      pageDots: false,
      imagesLoaded: true,
    });
  }

  componentWillUnmount() {
    if (this.flickity) {
      this.flickity.destroy();
    }
  }
  render() {
    const { items } = this.props;
    return (
      <div className="carousel-testimonial" ref={this.testimonialRef}>
        {items.map((item, index) => (
          <div className="carousel-cell-testimonial" key={index}>
            <img
              src={item.testimonials_icon?.url}
              alt={item.testimonials_icon?.alt || "Testimonial Image"}
            />
            <h3>
              <RichText render={item.testimonials_name} />
            </h3>
            <p class="font-qregular">
              <RichText render={item.testimonials_description} />
            </p>
            <div className="stars">★★★★★</div>
          </div>
        ))}
      </div>
    );
  }
}

export default TestimonialSection;