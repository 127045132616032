/*  Common */
export const API_ERROR = "api_error";
export const API_SUCCESS = "api_success";
export const GET_FOOTER = "get_footer";
export const URL_ROUTING = "url_routing";
export const GET_FEATURES = "get_features";
export const CHECK_MENU_HAS_DATA = "check_menu_has_data";
export const IS_OPEN_POLICY = "is_open_policy";
export const SET_MODULE_DATA_COUNT = "set_module_data_count";
export const SET_FAQ_DATA = "set_faq_data";
export const IS_OPEN_CLINIC = "is_open_clinic";
export const SET_CLINIC_POPUP_RESPOND = "set_clinic_popup_respond";
export const IS_OPEN_SWITCH_ACCOUNT = "is_open_switch_account";
export const MASTER_USER_DETAILS = "master_user_details";
export const TEENS_ON_BEAM = "teens_on_beam";
export const IS_CAUTION_MODAL_OPEN = "is_caution_modal_open";
export const WELCOME_CONDITION_MODEL = "welcome_conditon_model";
export const WORKOUT_SEARCH_SUGGESTION_LIST = "workout_search_suggestion_list";
export const SET_BODY_WORKOUT_JSON = "set_body_workout_json";
export const WELCOME_CONDITION_MODEL_BY_HOWITWORKS =
  "welcome_condition_model_howitworks";
export const IS_TEST_TAKEN_TODAY = "is_test_taken_today";
export const IS_CLINICIAN_SESSION = "is_clinician_session";
export const OPEN_CLINICIAN_PRE_SESSION_POPUP =
  "is_clinician_pre_session_popup";
export const OPEN_CLINICIAN_POST_SESSION_POPUP =
  "is_clinician_post_session_popup";
export const CLINICIANS_GROUP_SESSION_DATA = "clinicians_group_session_data";
export const GET_CONDITION_BANNER = "get_condition_banner";
/* Login */
export const LOGIN_PROCESS = "login_process";
export const LOGIN_SUCCESS = "login_success";
export const LOGIN_FAIL = "login_fail";
export const CHECK_ISAUTH = "check_isauth";
export const LOGIN_MODEL_OPEN = "login_model_open";
export const FORGOT_MODEL_OPEN = "forgot_model_open";
/* Register */
export const REGISTER_NEXT_STEP = "register_next_step";
export const REGISTER_SET_STEP = "register_set_step";
export const REGISTER_PREV_STEP = "register_prev_step";
export const REGISTER_PROCESS = "register_process";
export const REGISTER_SUCCESS = "register_success";
export const REGISTER_FAIL = "register_fail";
export const REGISTERFORM_UPDATE = "registerform_update";
export const CLEAR_REGISTERFORM = "clear_registerform";
export const REGISTER_GET_INSTRUCTOR = "register_get_instructor";
export const REGISTER_SET_CONDITION_INDEX = "register_set_condition_index";
export const SHOW_HEADER = "show_header";
export const WORKOUT_DETAIL = "workout_detail";
export const UPDATE_WORKOUT_DETAIL = "update_workout_detail";
export const WORKOUT_LIST = "workout_list";
export const SCHEDULE_MODEL_OPEN = "schedule_model_open";
export const SCHEDULE_MODEL_TYPE = "schedule_model_type";
export const PUSH_HISTORY = "push_history";
export const CLEAR_WORKOUT_DETAIL = "clear_workout_detail";
export const INSTRUCTOR_LIST = "instructor_list";
export const CLEAR_WORKOUT_LIST = "clear_workout_list";
export const CLEAR_INSTRUCTOR_LIST = "clear_instructor_list";
export const CLEAR_INSTRUCTOR_DETAIL = "clear_instructor_detail";
export const ONDEMAND_WORKOUT_SAVE = "ondemand_workout_save";
export const ONDEMAND_WORKOUT_UNSAVE = "ondemand_workout_unsave";
export const ONDEMAND_DETAIL_SAVEVIDEO = "ondemand_detail_savevideo";
export const INSTRUCTOR_DETAIL = "instructor_detail";
export const INSTRUCTOR_DETAIL_SAVEVIDEO = "instructor_detail_savevideo";
export const UPDATE_SCHEDULE_MODEL_OPEN = "update-schedule_model_open";
export const ONDEMAND_DETAIL_SUB_SAVEVIDEO = "ondemand_detail_sub_savevideo";
export const START_LOADER = "start_loader";
export const STOP_LOADER = "stop_loader";
export const CANCEL_BANNER = "cancel_banner";
export const CANCEL_BANNER_KD = "cancel_banner_kd";
export const CANCEL_BANNER_PC = "cancel_banner_pc";
export const CHANGE_CMS_MODE = "change_cms_mode";
export const CHANGE_GROUP_CMS_MODE = "change_group_cms_mode";
export const UPDATE_COUNTRIESLIST = "update_countrieslist";
export const UPDATE_HEALTHCONDITION = "update_healthcondition";
export const UPDATE_HEARABOUT = "update_hearabout";
export const UPDATE_WARNING_BANNER = "update_warning_banner";
export const UPDATE_CLINIC = "update_clinic";
export const UPDATE_PROMOCODEDATA = "update_promocodedata";
export const REGISTER_GOTO_STEP = "register_goto_step";
export const ASSIGN_FACEBOOK_VALUES = "assign_facebook_values";
export const ASSIGN_GOOGLE_VALUES = "assign_google_values";
export const UPDATE_CONDITION_RELATION = "update_condition_relationships";
export const SET_CONDITION = "set_condition";
export const SET_MENU_CONDITION = "set_menu_condition";
export const SET_INITIAL_CONDITION = "set_initial_condition";
export const SET_CHALLENGE_TAG = "set_challenge_tag";
export const LOGGED_USERDATA = "logged_userdata";
export const LOGGED_USER_OTHER_DATA = "logged_user_other_data";
export const LOGGED_USERDATA_UPDATE = "logged_userdata_update";
export const IP_DATA = "logged_user_ip_address";
export const UPDATE_MEMBERSHIP_PLANS = "update_membership_plans";
export const INNER_REGISTER_NEXT_STEP = "inner_register_next_step";
export const INNER_REGISTER_PREV_STEP = "inner_register_prev_step";
export const RESET_REGISTER = "reset_register";
export const UPDATE_AFTER_STATE = "update_after_state";
export const PACKSTER_GOTO_REGISTER = "packster_goto_register";
export const CLEAR_AFTER_STATE = "clear_after_state";
export const UPDATE_NOTIFICATION_MSG = "update_notification_msg";
export const CUSTOM_NOTIFICATION_UPDATE = "custom_notification_update";
export const UPDATE_OFFSET = "update_offset";
export const SET_GOBACK = "set_goback";
export const SET_CURRENT_POSITION = "set_current_position";
export const UPDATE_LICENSE_LIST = "update_license_list";
export const UPDATE_COUNTRY_PLANS = "update_country_plans";
export const SET_EXPLORE_CONDITION = "set_explore_condition";
export const SET_ACCOUNT_LOCKED_POPUP = "set_account_locked_popup";
export const IS_PAYMENT_ERROR_POPUP_OPEN = "is_payment_error_popup_open";

export const SET_SLIDER_VALUE = "set_slider_value";
export const SET_DATE_VALUE = "set_date_value";
export const SET_TIME_VALUE = "set_time_value";
export const SET_DATEPICKER_VALUE = "set_datepicker_value";
export const CLEAR_ACTIVITY_LIST = "clear_activity_list";
export const SET_ACTIVITY_LIST = "set_activity_list";
export const UPDATE_JSON_SCHEMA_FORM_DATA = "update_json_schema_form_data";
export const CHECK_CONDITION_CODE = "check_condition_code";
export const SET_LOADING = "set_loading";
export const SET_PAGE_TITLE = "set_page_title";
export const SET_NEW_USER = "set_new_user";
export const SET_MASTER_USER_ID = "set_master_user_id";
export const SET_ALL_USER_LIST = "set_all_user_list";
export const SET_SIGN_UP_USER_LIST = "set_signup_user_list";
export const USER_IP_BASED_COUNTRY_DETAILS = "user_ip_country_details";
/* liveclass */
export const LIVECLASS_DETAIL = "liveclass_detail";
export const CLEAR_LIVECLASS_DETAIL = "clear_liveclass_detail";
export const LIVECLASS_DETAIL_SUB_SAVEVIDEO = "liveclass_detail_sub_savevideo";
export const LIVECLASS_PRISMIC_PARAM = "liveclass_prismic_param";
export const SET_POLL_MODAL = "set_poll_modal";
export const UPDATE_POLL_VALUES = "update_poll_values";
export const LIVECLASS_FILTER_INSTRUCTOR = "liveclass_filter_instructor";
/* Group */
export const GROUP_ONDEMAND_WORKOUT_UNSAVE = "group_ondemand_workout_unsave";
export const GROUP_ONDEMAND_WORKOUT_SAVE = "group_ondemand_workout_save";
export const GROUP_ONDEMAND_LIST = "group_ondemand_list";
export const CLEAR_GROUP_ONDEMAND_LIST = "clear_group_ondemand_list";
export const ONDEMAND_CMS_MODEL_OPEN = "ondemand_cms_model_open";
export const GROUP_JOIN_MODAL_OPEN = "group_join_modal_open";
export const IMAGE_CROP_MODEL_OPEN = "image_crop_model_open";
export const SET_CROPPED_IMAGE = "set_cropped_image";
export const CHECK_VIDEO_EXIST = "check_video_exist";
export const GROUP_CMS_MODEL_OPEN = "group_cms_model_open";
export const GROUP_IS_LEADER = "group_is_leader";
export const GROUP_IS_ADMIN = "group_is_admin";
export const GROUP_ABOUT_DETAILS = "group_about_details";
export const CLEAR_GROUP_ABOUT_DETAILS = "clear_group_about_details";
export const SET_GROUP_CREATE_MODE = "set_group_create_mode";
export const SET_FILE_DATA = "set_file_data";
/* programs */
export const GET_USER_PROGRAMS = "get_user_programs";
export const SET_AFTER_POPUP = "set_after_popup";
export const SET_ENABLE_VIDEO = "set_enable_video";
export const SET_END_VIDEO = "set_end_video";
export const SET_BEFORE_POPUP = "set_before_popup";
export const SET_POST_POLL_MODAL = "set_post_poll_modal";
export const SET_RESEARCH_MODAL = "set_research_modal";
export const SET_SAFETY_MODAL = "set_safety_modal";
export const SET_SURVEY_MODAL = "set_survey_modal";
export const SET_WARNING_MODAL = "set_warning_modal";
export const SET_CONDITION_MODAL = "set_condition_modal";
export const SET_PLAY_ONDEMAND_MODAL = "set_play_ondemand_modal";
export const UPDATE_USER_PROGRAMS = "update_user_programs";
export const PROGRAM_DETAIL = "program_detail";
export const SET_PROGRAM_ACTIVE_SLIDER = "set_program_active_slider";
export const SET_PROGRAM_ACTIVE_TYPE = "set_program_active_type";
export const ONDEMAND_WORKOUT_LIST = "ondemand_workout_list";
export const ONDEMAND_BROWSE_CATEGORIES = "ondemand_browse_categories";
export const SHOW_BROWSE_CATEGORIES = "show_browse_categories";
/** GOALS */
export const SET_PROGRAM_GOALS_LIST = "set_program_goals_list";
export const SET_DASHBOARD_PROGRAM_GOALS_LIST =
  "set_dashboard_program_goals_list";
export const IS_GOAL_MODAL_OPEN = "is_goal_modal_open";
export const CREATE_UPDATE_GOALS_LIST = "create_update_goals_list";
/** Programme Resources */
export const SET_PROGRAM_RESOURCES_LIST = "set_program_Resources_list";
// Programme Survey
export const SET_ACTIVE_SURVEY = "set_active_survey";
export const SET_FORMDATA = "set_formdata";
/** Dashboard */
export const IS_FITNESS_TEST_MODAL_OPEN = "is_fitness_test_modal_open";
export const GET_FITNESS_TEST_FORM = "get_fitness_test_form";
export const IS_TAKE_TEST_CLICKED = "is_take_test_clicked";
export const GET_TEST_SCORE = "get_test_score";
export const SCHEDULE_LIVE_CLASS_LIST = "schedule_live_class_list";
export const ONDEMAND_BODY_WORKOUT_LIST = "ondemand_body_workout_list";
export const COMPLETED_WORKOUT_COUNT = "completed_workout_count";
export const DYNAMIC_DASHBOARD_CONTENT_DATA = "dynamic_dashbiard_content_data";
export const DYNAMIC_DASHBOARD_YOUR_SESSION = "dynamic_dashboard_your_session";
export const DYNAMIC_DASHBOARD_WEIGHT = "dynamic_dashboard_weight";
export const SESSION_JOIN = "session_join";
export const GET_SESSION = "get_session";
/** Blogs */
export const GET_BLOG_TITLES = "get_blog_titles";
export const WELCOME_CONDITION_MODEL_BY_BLOG = "is_blog_modal_open";
export const PREVIOUS_SELECTED_BLOGS = 'previous_selected_blogs';
export const PREVIOUS_SELECTED_TAGS = 'previous_selected_tags';
export const PREVIOUS_SELECTED_TAGS_VALUES = 'previous_selected_tags_values';

/** Home */
export const GET_PAGE_DATA = "get_page_data";

/* User Badges */
export const FETCH_BADGES_REQUEST = 'fetch_badges_request';
export const FETCH_BADGES_SUCCESS = 'fetch_badges_success';
export const FETCH_BADGES_FAILURE = 'fetch_badges_failure';
