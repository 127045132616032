const initialState = {
    badgeDetails: [], // Store badge details
    loading: false,   // Track loading state
    error: null,      // Capture any errors
};

export default function badgesReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_BADGES_REQUEST':
            return { ...state, loading: true, error: null };
        case 'FETCH_BADGES_SUCCESS':
            return { ...state, loading: false, badgeDetails: action.payload };
        case 'FETCH_BADGES_FAILURE':
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}
