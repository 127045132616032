import React, { Component } from "react";
import Flickity from "flickity";
import "flickity/dist/flickity.min.css"; // Import Flickity CSS

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.carouselRef = React.createRef(); // Create a ref to the carousel container
  }

  componentDidMount() {
    // Initialize Flickity when the component mounts
    this.flickity = new Flickity(this.carouselRef.current, {
      wrapAround: false,
      autoPlay: false,
      cellAlign: "left",
      contain: true,
      pageDots: false,
      imagesLoaded: true, // Ensure images are loaded before initializing
      //freeScroll: true,
      //groupCells: true,
    });
  }
  componentWillUnmount() {
    // Clean up Flickity instance when the component is unmounted
    if (this.flickity) {
      this.flickity.destroy();
    }
  }
  render() {
    const { items } = this.props;
    return (
      <div className="carousel" ref={this.carouselRef}>
        {items.map((item, index) => (
          <div className="carousel-cell" key={index}>
            <img
              src={item.slider_image?.url}
              alt={item.slider_image?.alt || "Carousel Image"}
            />
          </div>
        ))}
      </div>
    );
  }
}

export default Carousel;