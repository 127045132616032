import React from "react";
import * as constand from "../../constant";

const BadgesPopupComponent = ({ badge, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="badge-modal-overlay" onClick={onClose}>
      <div className="badge-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="badge-close-button font-18 font-medium font-semibold" onClick={onClose}>
          X
        </button>
        <div className="popup-header">
          <img
            src={constand.AWS_BADGE_IMAGE_URL + badge.imageUrl} 
            alt={badge.name}
            className={badge.count > 0 ? "img-fluid logo-size" : "img-fluid m-b-10 logo-size black-and-white"}
          />
          <h3 className="black-txt text-center font-18">{badge.name}</h3>
        </div>
        <div className="modal-body">
          <p>{badge.description || "No description available."}</p>
        </div>
      </div>
    </div>
  );
};

export default BadgesPopupComponent;
