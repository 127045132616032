import React from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { Cookies } from "react-cookie-consent";
import { openClinicianPreSessionPopup, openClinicianPostSessionPopup, cliniciansGroupSessionData, saveClinicianSessionCount } from "../../actions";
import * as constand from "../../constant"; 
class ClinicianSessionPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preSessionExpect: 1,
            postSessionAttend: 1
        };
    } 

    componentDidMount() {
        let sessionData = Object.assign({}, this.props.clinician_group_session_data);
        if (sessionData && sessionData.expectedCount) {
            this.setState({ postSessionAttend: sessionData.expectedCount })
        }
    }

    onCloseModal = () => {
        if (this.props.type == "preSession") {
            this.props.openClinicianPreSessionPopup(false);
            this.props.afterCount();
        } else {
            this.props.openClinicianPostSessionPopup(false);

        }
    } 

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    } 

    sessionPopupSubmit = (isPreSession = false) => { 
        let data = isPreSession ? { preSessionExpect: this.state.preSessionExpect, isPreSession: true } : { postSessionAttend: this.state.postSessionAttend, isPreSession: false }
        let sessionData = Object.assign({}, this.props.clinician_group_session_data);
        sessionData.data = data;
 
        this.props.saveClinicianSessionCount(sessionData).then(() => {
            this.props.cliniciansGroupSessionData(sessionData)
        });
        if (isPreSession) {
            this.props.openClinicianPreSessionPopup(false);
            this.props.afterCount();
        } else {
            let condition = (Cookies.get('condition') != 'undefined' && Cookies.get('condition')) ? Cookies.get('condition').toLowerCase() : constand.KR_CONDITION;
            let url = '/on-demand/' + condition
            this.props.history.push(url)
            this.props.openClinicianPostSessionPopup(false);
        }
        this.setState({ preSessionExpect: 1, postSessionAttend: 0 });
    } 


    render() { 
        return (

            <Modal
                className="removebbg-popup"
                open={this.props.is_model_open}
                onClose={this.onCloseModal}
                center
            >
                { 
                    <div
                        className="modal-dialog common_design modal-width--custom live-classes--popup"
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-header header-styling  border-0">
                                <h5
                                    className="modal-title1 text-center white-txt col-md-12 p-0 font-book font-24"
                                    id="exampleModalLabel "
                                >
                                    {this.props.title}
                                </h5>
                                {this.props.isCloseRequired && <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span
                                        aria-hidden="true"
                                        onClick={() => this.onCloseModal()}
                                    >&times;</span>
                                </button>
                                }
                            </div>
                            {<div className="row justify-content-center align-items-center clinician-session-popup">
                                <div className="input_section col-md-10 m-t-10">
                                    {
                                        this.props.type == "preSession" &&
                                        <div className="form-group">
                                            <p className="font-18 font-qregular black-txt">
                                                Are you running a group class with your patients in clinic?
                                                If so, please let us know how many people you expect to attend this session (excluding yourself)…
                                            </p>
                                            <div className="row m-l-0 d-inline">
                                                <input
                                                    className="form-control col-md-8 col-8 group-session-input"
                                                    type="number"
                                                    value={this.state.preSessionExpect}
                                                    onChange={this.handleChange}
                                                    min="1"
                                                    name="preSessionExpect"
                                                />
                                                <button className="btn btn-login popup-btn w-30 float-right col-md-4 col-4 m-l-10 clinician-popup-btn" onClick={() => this.sessionPopupSubmit(true)}>Submit
                                                </button>
                                            </div>

                                            <div className="row m-t-10 justify-content-center">
                                                <button className="btn btn-blue-inverse popup-btn w-40" onClick={() => this.onCloseModal()}>I’m not running a group class
                                                </button>

                                            </div>
                                        </div>
                                    }

                                    {
                                        this.props.type == "postSession" &&
                                        <div className="form-group">
                                            <p className="font-18 font-qregular black-txt">Thanks for hosting an in-patient group class! Can you confirm the number of people who completed this session please (excluding yourself)?
                                            </p>
                                            <div className="row m-l-0 d-inline">
                                                <input
                                                    className="form-control col-md-8 col-8 group-session-input"
                                                    type="number"
                                                    value={this.state.postSessionAttend}
                                                    onChange={this.handleChange}
                                                    min="1"
                                                    name="postSessionAttend"
                                                />
                                                <button className="btn btn-login popup-btn w-30 float-right col-md-4 col-4 m-l-10 clinician-popup-btn" onClick={() => this.sessionPopupSubmit()}>Submit
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                }
            </Modal>
        );
        
    }
}

const mapStateToProps = state => {
    return {
        clinician_group_session_data: state.header.clinician_group_session_data,
        init_condition: state.auth.initial_condition
    }; 
};

const mapDispatchToProps = {
    openClinicianPreSessionPopup,
    openClinicianPostSessionPopup,
    cliniciansGroupSessionData,
    saveClinicianSessionCount
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClinicianSessionPopup); 