import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import { start_loader, stop_loader, setStep, getTagsBasedOnType, setConditionIndex, setPageTitle, updateUserConditions, checkConditionHasTagCode, onboardingUserTrack } from "../../actions";
import * as constand from "../../constant";
import { commonService } from "../../_services";
import GoalSlider from "../Common/GoalsSlider";

class GoalSettingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            current_index: 0,
            goal_data: {},
            registerFormvalues: {},
            displayValue: "", // The value shown to the user
            internalValue: "", // The ISO date value used internally
        };
    }
    componentDidMount() {
      
    }

    updateGoalData = (field) => {
      const targetFieldName = field.target.name;
      const targetFieldValue = field.target.value;
      
      // Create the new key-value pair
      const newField = { [targetFieldName]: targetFieldValue };
      
      // Update the state immutably
      this.setState((prevState) => ({
        goal_data: {
          ...prevState.goal_data, // Retain existing properties
          ...newField, // Add or update the new field
        },
      }));
    };
    
    /**
    submit  selection
    **/
    submitData() {
      // if (this.props.pageFrom == 'mycondition') {
      //     //myconditions
      //     if (this.props.isUpdateMyConditions) {
      //         this.props.updateUserTags()
      //     }
      //     else
      //         this.props.pageFrom == 'register' ? this.onboardingUserTrack(21, 'forward') : this.props.setStep(21, 'forward');
      // } else {
      //     //onboarding
      //     if (this.props.registerFormvalues.country == constand.ukCountry && this.props.registerFormvalues.health_condition[this.props.condition_index].tag == constand.CANCER_CONDITION) {
      //           this.props.setStep(21, 'forward')
      //     } else {
      //       if (this.props.isConditionHasCode)
      //           this.props.pageFrom == 'register' ? this.onboardingUserTrack(29, 'forward') : this.props.setStep(29, 'forward');
      //       else if (this.props.condition_index <
      //         this.props.registerFormvalues.health_condition.length - 1) {
      //         this.props.setConditionIndex(this.props.condition_index + 1)
      //         this.props.pageFrom == 'register' ? this.onboardingUserTrack(11, 'forward') : this.props.setStep(11, 'forward');//profession
      //       } else {
      //         this.props.pageFrom == 'register' ? this.onboardingUserTrack(23, 'forward') : this.props.setStep(23, 'forward'); // straight away go for membership options
      //       }
              
      //     }
      // }
      if (!this.state.goal_data.goal) {
        toast.error("Please let us know what is your goal ?");
        } else if (!this.state.goal_data.action) {
          toast.error("Please Describe what you will do ?");
        } else if (!this.state.goal_data.location) {
          toast.error("Your Goal Location is mandatory");
        } else if (!this.state.goal_data.withWhom) {
          toast.error("Let us know with whom you are going to achieve it");
        } else if (!this.state.goal_data.reviewDate) {
          toast.error("Review date for your goal is mandatory");
      } else {
        var temp = { ...this.props.registerFormvalues }; // Copy props
        temp.goal = this.state.goal_data; // Update the goal field
        this.props.registerformUpdate(temp); // Pass the updated object to the parent
        this.setState({ submitted: true });

        if (this.props.registerFormvalues.country == constand.ukCountry && this.props.registerFormvalues.health_condition[this.props.condition_index].tag == constand.CANCER_CONDITION) {
              this.props.setStep(21, 'forward')
        } else {
          if (this.props.isConditionHasCode)
              this.props.pageFrom == 'register' ? this.onboardingUserTrack(29, 'forward') : this.props.setStep(29, 'forward');
          else if (this.props.condition_index <
            this.props.registerFormvalues.health_condition.length - 1) {
            this.props.setConditionIndex(this.props.condition_index + 1)
            this.props.pageFrom == 'register' ? this.onboardingUserTrack(11, 'forward') : this.props.setStep(11, 'forward');//profession
          } else {
            this.props.pageFrom == 'register' ? this.onboardingUserTrack(23, 'forward') : this.props.setStep(23, 'forward'); // straight away go for membership options
          }
        }
      }
    }

    skipGoalSetting(){
        //  onboarding
        if (this.props.registerFormvalues.country == constand.ukCountry && this.props.registerFormvalues.health_condition[this.props.condition_index].tag == constand.CANCER_CONDITION) {
            this.props.setStep(21, 'forward')
        } else {
        if (this.props.isConditionHasCode)
            this.props.pageFrom == 'register' ? this.onboardingUserTrack(29, 'forward') : this.props.setStep(29, 'forward');
        else if (this.props.condition_index <
            this.props.registerFormvalues.health_condition.length - 1) {
            this.props.setConditionIndex(this.props.condition_index + 1)
            this.props.pageFrom == 'register' ? this.onboardingUserTrack(11, 'forward') : this.props.setStep(11, 'forward');//profession
        } else
            this.props.pageFrom == 'register' ? this.onboardingUserTrack(23, 'forward') : this.props.setStep(23, 'forward'); // straight away go for membership options
      }
    }

    defaultTextboxOpenCheck = (item) => {
        let temp = [];
        if (this.props.registerFormvalues.kidney_care_provider.length > 0) {
            temp = [...this.props.registerFormvalues.kidney_care_provider];
            let index = this.props.registerFormvalues.kidney_care_provider.findIndex(x => x === item);
            if (index > -1) {
                temp.splice(index, 1);
            } else {
                temp.push(item);
            }
        } else {
            temp = [...this.props.registerFormvalues.kidney_care_provider];
            temp.push(item);
        }
        let filteredTag = commonService.kdProviderTagName(this.state.kidney_care_provider, temp, 'Other');
        if (temp && temp.length > 0 && filteredTag && filteredTag.tag == 'Other') {
            this.setState({ is_kd_other_care_provider: true })
        } else {
            this.setState({ is_kd_other_care_provider: false })
        }

    }

    onboardingUserTrack = (step, flow = false) => {
        let registerData = {
            registerEmail: this.props.registerFormvalues.email,
            formValues: this.props.registerFormvalues
        }
        this.props.onboardingUserTrack(registerData).then((res) => {
            let direction = flow ? flow : '';
            this.props.setStep(step, direction)
        })
    }

    handleFocus = (event) => {
       // Set the minimum date to today (to prevent past dates)
      const today = new Date();
      const yyyy = today.getFullYear();
      const mm = String(today.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
      const dd = String(today.getDate()).padStart(2, "0");
      event.target.min = `${yyyy}-${mm}-${dd}`;

      const value = event.target.value;
      if (value) {
        // Convert 'DD / MM / YYYY' to 'YYYY-MM-DD'
        const [day, month, year] = value.split(" / ");
        event.target.value = `${year}-${month}-${day}`;
      }
      event.target.type = "date"; // Switch to 'date' input
    };
  
    handleBlur = (event) => {
      event.target.type = "text"; // Switch back to 'text' input
      const today = new Date();
      const value = event.target.value;
      if (value) {
        // Use your existing onBlur function logic here:
        const enteredDate = new Date(value);

        // Check if the entered date is valid and not in the past
        if (isNaN(enteredDate.getTime()) || enteredDate < today.setHours(0, 0, 0, 0)) {
          toast.error("Invalid or past date entered. Please select a valid future date.");
          event.target.value = ""; // Clear the invalid value
          return;
        }

        const [year, month, day] = value.split("-");
        event.target.value = day + " / " + month + " / " + year; // Format to 'DD / MM / YYYY'
      }
    };
    
    updateGoalConfidence(e){
      const newField = { confidenceLevel: e * 2 };
      
      // Update the state immutably
      this.setState((prevState) => ({
        goal_data: {
          ...prevState.goal_data, // Retain existing properties
          ...newField, // Add or update the new field
        },
      }));
    }


    updateGoalImportance(e){
      const newField = { importanceLevel: e * 2 };
      
      // Update the state immutably
      this.setState((prevState) => ({
        goal_data: {
          ...prevState.goal_data, // Retain existing properties
          ...newField, // Add or update the new field
        },
      }));
    }


    render() {
        return (

            <div className="step33">
                {this.props.pageFrom != 'mycondition' &&
                  <div className="header_block">
                    <h3 className="text-center ">
                      <span
                          className="pull-left pointer"
                          onClick={() => this.props.setStep(19, 'backward')}
                      >
                            <img className="arrow-img" src={constand.WEB_IMAGES + "arrow-left.png"} />
                          </span>{this.props.registerFormvalues.firstname ? this.props.registerFormvalues.firstname + ': ' : ''}
                          now let’s set your goal.
                          <Link
                              to="/home"
                              className="close-register orangefont"
                          >
                            X
                        </Link>
                        <br />
                        <span className="font-16 sub-title-spacing">(You can always edit this later in your profile)</span>
                    </h3>
                  </div>
                }
              <div className="row justify-content-center align-items-center padding-left-right-120">
                <div className="col-lg-12 clearfix">
                  <div className="row account_basic_info">
                    <p className="font-18 black-txt font-semibold col-sm-12 header-align">Many people find that having a goal in mind gives them something to work towards, motivates them to stay on track and provides a measure of how well they are doing</p>
                  </div>
                  <div className="row account_basic_info">
                    <div className="col-lg-12 col-md-12 col-sm-12 m-b-10 p-l-0">
                      <label
                        className="font-14 black-txt font-semibold col-sm-12  pl-0"
                        htmlFor="goal"
                      >
                        My goal is
                      </label>
                      <input
                        className="form-control round-input_field font-qregular input-control pl-2"
                        name="goal"
                        placeholder="e.g. To keep my bones strong"
                        type="text"
                        onBlur={this.updateGoalData}
                      />
                      {/* {this.state.submitted && this.props.registerFormvalues && !this.state.goal_data.goal && (
                      <p className="help-block text-danger">
                        Your Goal is mandatory to submit
                      </p>
                    )} */}
                    </div>
                  </div>
                  <div className="row account_basic_info">
                    <div className="col-lg-6 col-md-6 col-sm-6 m-b-10 p-l-0">
                      <label
                        className="font-14 black-txt font-semibold col-sm-12 pl-0"
                        htmlFor="action"
                      >
                        What will I do...
                      </label>
                      <input
                        className="form-control round-input_field font-qregular input-control pl-2"
                        name="action"
                        placeholder="e.g. Exercise twice a week"
                        type="text"
                        onBlur={this.updateGoalData}
                      />
                      {/* {submitted && !this.props.userData.name && (
                        <p className="help-block text-danger">
                          Your Action is mandatory to submit
                        </p>
                      )} */}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 m-b-10 p-l-0">
                      <label
                        className="font-14 black-txt font-semibold col-sm-12  pl-0"
                        htmlFor="location"
                      >
                        Where will I do it...
                      </label>
                      <input
                        className="form-control round-input_field font-qregular input-control pl-2"
                        name="location"
                        placeholder="e.g. At home"
                        type="text"
                        onBlur={this.updateGoalData}
                      />
                      {/* {submitted && !this.props.userData.lastName && (
                        <p className="help-block text-danger">
                          Your Location is mandatory to submit
                        </p>
                      )} */}
                    </div>

                  </div>
                  <div className="row account_basic_info">
                    <div className="col-lg-6 col-md-6 col-sm-6 m-b-10 p-l-0">
                      <label
                        className="font-14 black-txt font-semibold col-sm-12 pl-0"
                        htmlFor="withWhom"
                      >
                        With who...
                      </label>
                      <input
                        className="form-control round-input_field font-qregular input-control pl-2"
                        name="withWhom"
                        placeholder="e.g. My family"
                        type="text"
                        onBlur={this.updateGoalData}
                      />
                      {/* {submitted && !this.props.userData.name && (
                        <p className="help-block text-danger">
                          This information is required
                        </p>
                      )} */}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 m-b-10 p-l-0">
                      <label
                        className="font-14 black-txt font-semibold col-sm-12  pl-0"
                        htmlFor="reviewDate"
                      >
                        Date for review
                      </label>
                      <input
                        className="form-control round-input_field font-qregular input-control date-input pl-2"
                        name="reviewDate"
                        placeholder="DD / MM / YYYY"
                        type="text"
                        onFocus={this.handleFocus}
                        onChange={this.updateGoalData}
                        onBlur={this.handleBlur}
                      />
                    </div>

                  </div>
                  <div className="row account_basic_info">
                    <p className="font-16 text-label goal-announcemnts mx-0">The following is how confident and how important your goal is to you. Please rate these on the scales.</p>
                  </div>
                  <div className="row account_basic_info m-t-20">
                    <div className="col-lg-6 col-md-6 col-sm-6 m-b-10 p-l-0">
                      <label
                        className="font-14 black-txt font-semibold col-sm-12  pl-0"
                      >
                        How confidently can I achieve goal
                      </label>
                      <div className="range-slider-container">
                        <GoalSlider
                          title={""}
                          propsValue={this.state.goal_data.confidenceLevel}
                          handleChange={(e) =>this.updateGoalConfidence(e)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 m-b-10 p-l-0">
                      <label
                        className="font-14 black-txt font-semibold col-sm-12  pl-0"
                      >
                        How important is this goal to me
                      </label>
                      <div className="range-slider-container">
                        <GoalSlider
                          title={""}
                          propsValue={this.state.goal_data.importanceLevel}
                          handleChange={(e) =>this.updateGoalImportance(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row account_basic_info">
                    <div className="col-lg-12 col-md-12 col-sm-12 m-b-10 p-l-0">
                      <div className="col-lg-6 col-md-6 col-sm-12 m-b-10 p-l-0">
                        <p className="font-16 link-text colorblue">Try to think about how you can make this goal more achievable</p>
                      </div>
                    </div>
                  </div>
                  <div className="row account_basic_info m-t-20">
                    <div className="col-lg-6 col-md-6 col-sm-6 m-b-10 p-l-0 black-txt">
                      <button
                        className="btn btn-blue-inverse float-right w-100 font-medium font-16 text-center save-btn h-3"
                        onClick={() => this.skipGoalSetting()}
                      >
                        Skip
                      </button>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 m-b-10 p-l-0">
                      <button
                        onClick={() => this.submitData()}
                        className="btn bluebtn float-left w-100 font-medium font-16 text-center save-btn h-3"
                      >
                        {this.props.isUpdateMyConditions ? "Save Changes" : "Next"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        condition_index: state.register.condition_index,
        isConditionHasCode: state.register.isConditionHasCode,
        goalData:state.register.goal_data
    };
};

const mapDispatchToProps = { start_loader, stop_loader, setStep, getTagsBasedOnType, setConditionIndex, setPageTitle, updateUserConditions, checkConditionHasTagCode, onboardingUserTrack };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GoalSettingComponent);
