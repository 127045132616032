import React from 'react';
import { connect } from "react-redux";
import * as constand from "../../constant";
import { getUserBatches } from "../../actions";
import BadgesPopupComponent from "./BadgesPopupComponent";
import BadgeAchievementPopupComponent from "./BadgeAchievementPopupComponent";

class BadgesDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openDialog: false,
            selectedBadge: null,
            totalBadges: 0,
        };
    }

    async componentDidMount() {
        await this.props.getUserBatches();
        this.calculateTotalBadges(this.props.badgeDetails);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.badgeDetails !== this.props.badgeDetails) {
            this.calculateTotalBadges(this.props.badgeDetails);
        }
    }

    calculateTotalBadges = (badgeDetails) => {
        if (badgeDetails && badgeDetails.badges) {
            const totalBadges = badgeDetails.badges.reduce((total, badge) => total + (badge.count || 0), 0);
            console.log("Total Badges" + totalBadges);
            this.setState({ totalBadges });
        }
    };

    handleBadgeClick = (badge) => {
        this.setState({
            openDialog: true,
            selectedBadge: badge,
        });
    };

    handleCloseDialog = () => {
        this.setState({
            openDialog: false,
            selectedBadge: null,
        });
    };

    render() {
        const { badgeDetails } = this.props;
        const { openDialog, selectedBadge, totalBadges } = this.state;

        return (
            <section className={'badgeContent m-t-80'}>
                <div className="section-1">
                    <div className="col-md-12 ml-auto mr-auto badgess-title">
                        <p className="font-37 font-semibold font-medium m-t-6">Your Badges</p>
                        <p className="font-20 black-txt">
                            {totalBadges > 0
                                ? 'Keep it up! Exercise and earn more badges'
                                : 'Start exercising to earn your first badge'}
                        </p>
                    </div>
                </div>

                <div className="row justify-content-center align-items-center model-box m-b-30">
                    <div className="input_section col-md-12 col-sm-12 m-t-30">
                        <h3 className="font-18 black-txt">General Badges</h3>
                    </div>

                    {/* Loop through badgeDetails and render each badge */}
                    {badgeDetails && badgeDetails.badges && badgeDetails.badges.length > 0 ? (
                        badgeDetails.badges.map((badge, index) => (
                            <div key={index} className="input_section col-md-3 col-sm-6 m-t-30">
                                <div className="icon-box" onClick={() => this.handleBadgeClick(badge)}>
                                    <img
                                        src={constand.AWS_BADGE_IMAGE_URL + badge.imageUrl}
                                        id="icon"
                                        className={
                                            badge.count > 0
                                                ? 'img-fluid m-b-10 logo-size'
                                                : 'img-fluid m-b-10 logo-size black-and-white'
                                        }
                                        alt={badge.name || 'Badge'}
                                    />
                                    <h3 className="font-16 black-txt">{badge.name || 'Badge'}</h3>
                                    {badge.count && <p className="count">X {badge.count}</p>}
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No badges available at the moment.</p>
                    )}
                </div>

                {/* Render the modal if openDialog is true and a badge is selected */}
                {selectedBadge && (
                    <BadgesPopupComponent
                        badge={selectedBadge}
                        isOpen={openDialog}
                        onClose={this.handleCloseDialog}
                    />
                )}
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    badgeDetails: state.badges?.badgeDetails || [],
    loading: state.badges?.loading || false,
    error: state.badges?.error || null,
});

const mapDispatchToProps = (dispatch) => ({
    getUserBatches: () => dispatch(getUserBatches()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BadgesDetailPage);
