import React from "react";
import { connect } from "react-redux";
import {
  fetchUserDetails,
  getCountriesList,
  getRegionList,
  start_loader,
  stop_loader,
  updateUserdataRedex,
  healthCondition,
  get_membership,
  impersonateAccount,
  setStep,
  setExploreConditions,
  clearRegisterFormUpdate,
  setAllUserList,
  registerformUpdate,
  setMasterUserId,
  fetchUserOtherDetails
} from "../../actions";
import { commonService } from "../../_services";
import AccountBasicInfoComponent from "./AccountBasicInfoComponent";
import MyConditionComponent from "./MyConditionComponent";
import AccountMembershipComponent from "./AccountMembershipComponent";
import AccountCommunicationComponent from "./AccountCommunicationComponent";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Cookies } from "react-cookie-consent";
import * as constand from "../../constant";
import EmergencyInfoComponent from "./EmergencyInfoComponent";
import MovementPrefsInfoComponent from "./MovementPrefsInfoComponent";
import { Thumbs } from "swiper";
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";
import SubProfileRegisterComponent from "./SubProfileRegisterComponent"
import CryptoJS from 'crypto-js'; 
let _ = require('lodash');
const Cryptr = require('cryptr');
const cryptr = new Cryptr('myTotallySecretKey');


class AccountDetailComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account_tab: 1,
      userData: {},
      countriesList: [],
      isActiveClass: false,
      Loading: true,
      isConditionModalOpen: false,
      masterUserDetails: {},
      selectedUserName: '',
      userProfile: ''
    };
    this.tabChange = this.tabChange.bind(this);
    this.getCountriesList = this.getCountriesList.bind(this);
  }

  componentDidMount() {
    this.getCountriesList();
    let authData = Object.keys(this.props.logged_userData).length > 0 ? this.props.logged_userData : JSON.parse(localStorage.getItem('userDetails'));
    this.getUserDetail(authData, false);
  }

  tabChange(tab) {
    this.setState({ account_tab: tab });
  }

  getCountriesList() {
    this.props.start_loader();
    this.props.getCountriesList().then(
      response => {
        this.props.stop_loader();
        let countries = response.country ? response.country : [];
        this.setState({ countriesList: countries });
      },
      error => {
        this.props.stop_loader();
        toast.error(error);
      }
    );
  }

  getRegionList(countryId) {
    this.setState({ regionloader: true });
    this.props.getRegionList(countryId).then(
      response => {
        let region = response.region ? response.region : [];
        this.setState({ regionList: region, regionloader: false });
      },
      error => {
        this.setState({ regionloader: false });
        toast.error(error);
      }
    );
  }

  showMobileListName() {
    let label;
    switch (this.props.match.path) {
      case "/accounts/basicInformation":
        label = "Basic Information";
        break;
      case "/accounts/myConditions":
        label = "My Conditions";
        break;
      case "/accounts/membership":
        label = "Membership";
        break;
      case "/accounts/communicationPreferences":
        label = "Communication Preferences";
        break;
      case "/accounts/emergencyInfo":
        label = "Emergency Info";
        break;
      case "/accounts/movementprefs":
        label = "Movement Prefs";
        break;
      default:
        label = "Select";
        break;
    }
    return label;
  }
  closeModel = () => {
    //for close the login model
    this.setState({ isConditionModalOpen: false });
  };

  //fetch UserDetails
  getUserDetail = async (items, isImpersonate) => {
    let masterUserDetails = this.props.masterUserDetails != null ? this.props.masterUserDetails : JSON.parse(localStorage.getItem('masterUserDetails'));
    let userName = items && items.masterUserID ? items.name + ' ' + items.lastName : 'You'
    let userProfile =items && items.profilePic ? items.profilePic : ''
    this.setState({ Loading: true, selectedUserName: userName, userProfile: userProfile });
    if (isImpersonate) {
      await this.props.impersonateAccount(items.email)
    }
    this.props.fetchUserDetails().then((res) => {
      this.props.fetchUserOtherDetails()
      this.props.healthCondition().then(() => {
        this.props.setExploreConditions(this.props.logged_userData.UserConditions, this.props.healthcondition_list)
      });
      //active profile
      let master_item = commonService.setActiveProfile(items, masterUserDetails);
      this.setState({ Loading: false, masterUserDetails: master_item });
    });
  }

  //add new child profile
  addNewChildAccount = () => {
    this.setAddProfileNavigation();
    this.props.clearRegisterFormUpdate()
    this.setState({ isConditionModalOpen: true })
    let masterUserDetails = this.state.masterUserDetails; // JSON.parse(localStorage.getItem('masterUserDetails'))
    if (Object.keys(masterUserDetails).length > 0) {
      this.props.setAllUserList(masterUserDetails);
      let masterId = masterUserDetails.id || null;
      let encryptedString = null;
      if (masterId) {
        // encryptedString = cryptr.encrypt(masterId);
        encryptedString = CryptoJS.AES.encrypt(masterId.toString(), 'myTotallySecretKey').toString();
        encryptedString = encryptedString.replace(/\//g, '_');
      }
      this.props.setMasterUserId(encryptedString);
      //set master userid
      this.props.registerFormValues.masterUserID = encryptedString;
      this.props.registerformUpdate(this.props.registerFormValues);
      this.props.registerFormValues.firstname = "";
      this.props.healthCondition(true);
    }
    this.props.setStep(4);
  }

  //for check add profile navigation
  setAddProfileNavigation = () => {
  }
  render() {
    let condition = (Cookies.get('condition') != 'undefined' && Cookies.get('condition')) ? Cookies.get('condition').toLowerCase() : constand.KR_CONDITION;
    let authData = Object.keys(this.props.logged_userData).length > 0 ? this.props.logged_userData : JSON.parse(localStorage.getItem('userDetails'));
    console.log('this.props.match.path', this.props.match.path)
    return (
      <>
        <section className={((typeof Cookies.get('closeBanner') == 'undefined' || Cookies.get('closeBanner') == 'false') && !this.props.close_banner) || ((typeof Cookies.get('closeBannerKD') == 'undefined' || Cookies.get('closeBannerKD') == 'false') && !this.props.close_banner_kd) ? 'Account_details New_account_details' : 'Account_details New_account_details m-t-70'}>
          <div className="Account-bg text-center p-t-10 p-b-90" id="Account">
            <div className="container">
              <h3 className="font-medium font-37 darkblue-text m-t-50">
                Account Details
              </h3>
            </div>
          </div>
          {/* tab menu section start*/}
          <div className="tab_group mybeampg hideonmobile">
            <div className="container">
              <div className="row">
                <div className=" mx-auto tab_full">
                  <ul
                    className="nav nav-tabs small justify-content-left mx-auto  tab_ul"
                    role="tablist"
                  >
                    <li className="nav-item font-book font-15">
                      <Link
                        className={
                          (this.props.match.path === "/accounts/basicInformation"
                            ? "active"
                            : "") + "  nav-link font-semibold pointer font-book font-15"
                        }
                        to={"/accounts/basicInformation"}
                      >
                        Account Details<span className="blue_line"></span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          (this.props.match.path === "/accounts/myConditions"
                            ? "active"
                            : "") + "  nav-link font-semibold pointer font-book font-15"
                        }
                        to={"/accounts/myConditions"}
                      >
                        My Conditions<span className="blue_line"></span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          (this.props.match.path === "/accounts/membership"
                            ? "active"
                            : "") + " nav-link font-semibold pointer font-book font-15"
                        }
                        to={"/accounts/membership"}
                      >
                        Membership<span className="blue_line"></span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          (this.props.match.path === "/accounts/communicationPreferences"
                            ? "active"
                            : "") + "  nav-link font-semibold pointer font-book font-15"
                        }
                        to={"/accounts/communicationPreferences"}
                      >
                        Communication Preferences<span className="blue_line"></span>
                      </Link>
                    </li>
                    <li className="nav-item ">
                      <Link
                        className={
                          (this.props.match.path === "/accounts/emergencyInfo"
                            ? "active"
                            : "") + "  nav-link font-semibold pointer font-book font-15"
                        }
                        to={"/accounts/emergencyInfo"}
                      >
                        Emergency Info<span className="blue_line"></span>
                      </Link>
                    </li>
                    <li className="nav-item ">
                      <Link
                        className={
                          (this.props.match.path === "/accounts/movementprefs"
                            ? "active"
                            : "") + "  nav-link font-semibold pointer font-book font-15"
                        }
                        to={"/accounts/movementprefs"}
                      >
                        Movement Preferences<span className="blue_line"></span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* tap menu section end */}
          <div className="col-12 mb-3 d-block d-sm-block d-md-none d-lg-none">
            <div className="dropdown tab-btn newdropdown_menu w-100">
              <button
                type="button"
                className="btn btn-default font-medium font-14 tab-select dropdown-toggle"
                data-toggle="dropdown"
              >
                {this.showMobileListName()}
                <span className="slt"></span>
              </button>
              <div className="dropdown-menu">
                <Link
                  className={
                    (this.props.match.path === "/accounts/basicInformation"
                      ? "active"
                      : "") + " nav-link font-medium font-21 m-b-10"
                  }
                  to={"/accounts/basicInformation"}
                >
                  Basic Information
                </Link>
                {!this.props.logged_userData.isStudyParticipant && condition && !condition.includes('research') &&
                  <Link
                    className={
                      (this.props.match.path === "/accounts/myConditions"
                        ? "active"
                        : "") + " nav-link font-medium font-21 m-b-10"
                    }
                    to={"/accounts/myConditions"}
                  >
                    My Conditions
                  </Link>
                }
                <Link
                  className={
                    (this.props.match.path === "/accounts/membership"
                      ? "active"
                      : "") + " nav-link font-medium font-21 m-b-10"
                  }
                  to={"/accounts/membership"}
                >
                  Membership
                </Link>
                <Link
                  className={
                    (this.props.match.path === "/accounts/communicationPreferences"
                      ? "active"
                      : "") + "  nav-link font-medium font-21 m-b-10"
                  }
                  to={"/accounts/communicationPreferences"}
                >
                  Communication Preferences<span className="blue_line"></span>
                </Link>
                <Link
                  className={
                    (this.props.match.path === "/accounts/emergencyInfo"
                      ? "active"
                      : "") + "  nav-link font-medium font-21 m-b-10"
                  }
                  to={"/accounts/emergencyInfo"}
                >
                  Emergency Info<span className="blue_line"></span>
                </Link>
                <Link
                  className={
                    (this.props.match.path === "/accounts/movementprefs"
                      ? "active"
                      : "") + " nav-link font-medium font-21 m-b-10"
                  }
                  to={"/accounts/movementprefs"}
                >
                  Movement Preferences<span className="blue_line"></span>
                </Link>
              </div>
            </div>
          </div>
          {/* profile section start  */}
          <div className="account_tabs m-t-60 m-b-60 account_Tabs_new fullwidth_mobile">
            <div className="text-center w-100 loading_icon loadingnewalign">
              {this.state.Loading && (<AnimateLoaderComponent />)}
            </div>
            <div className="container-fluid mx-auto w-75 ">
              <div className="row">
                <div className="col-12 mb-3 profile_mobile_view_drop showonlyinmobile">
                  <div className="dropdown tab-btn w-100">
                    <button
                      type="button"
                      className="btn btn-default font-medium font-14 tab-select dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <div className="profile_selected_value">
                        <span className="profile_img active"><img src={
                          this.state.userProfile
                            ? constand.S3_API_IMG + '/profile/' + this.state.userProfile
                            : constand.WEB_IMAGES + "no-profile-pic.png"
                        } alt="" /></span> <span>{this.state.selectedUserName}</span></div>
                      <span className="slt"></span>
                    </button>
                    <div className="dropdown-menu profiledetailsusers">
                      {this.state.masterUserDetails && !condition.includes('research') && <li className="nav-item">
                        <button
                          disabled={this.state.masterUserDetails.id == this.props.logged_userData.id}
                          id={this.state.masterUserDetails.id}
                          className={
                            (this.state.masterUserDetails.activeClass
                              ? "active"
                              : "") + " nav-link font-medium font-21 m-b-10 btn_bg profile_selected_value"
                          }

                          onClick={() => this.getUserDetail(this.state.masterUserDetails, this.state.masterUserDetails.id)}
                        >
                          <span className="profile_img"><img src={
                            this.state.masterUserDetails.profilePic
                              ? constand.S3_API_IMG + '/profile/' + this.state.masterUserDetails.profilePic
                              : constand.WEB_IMAGES + "no-profile-pic.png"
                          } alt="" /></span>
                          <span className="profile_name">You</span>
                        </button>
                      </li>
                      }
                      {this.state.masterUserDetails && this.state.masterUserDetails.childUser && !condition.includes('research') && this.state.masterUserDetails.childUser.map((child_userData) => {
                        return (
                          <li className="nav-item">
                            <button
                              disabled={child_userData.id == this.props.logged_userData.id}
                              id={child_userData.id}
                              className={
                                (child_userData.activeClass
                                  ? "active"
                                  : "") + " nav-link font-medium font-21 m-b-10 btn_bg profile_selected_value"
                              }
                              onClick={() => this.getUserDetail(child_userData, child_userData.id)}
                            >
                              <span className="profile_img">
                                <img
                                  className="img-fluid rounded-circle"
                                  src={
                                    child_userData.profilePic
                                      ? constand.S3_API_IMG + '/profile/' + child_userData.profilePic
                                      : constand.WEB_IMAGES + "no-profile-pic.png"
                                  }
                                />
                              </span>
                              <span className="profile_name">{child_userData.name} {child_userData.lastName}</span>
                            </button>
                          </li>
                        )
                      })
                      }
                      {authData && authData.masterUserID == null && !condition.includes('research') &&
                        <li className="nav-item">
                          <button
                            className="nav-link font-medium font-21 m-b-30 adnewchild_btn"
                            onClick={() => this.addNewChildAccount()}
                          >
                            <span className="profile_name add_plud_circle m-r-20 m-l-5"><i className="fa fa-plus-circle"></i></span>
                            <span className="profile_name">Add new child</span>
                          </button>
                        </li>
                      }
                    </div>
                  </div>
                </div>
                {!condition.includes('research') && <div className="col-md-4 col-lg-2 mb-3 hideonmobile">
                  <ul
                    className="nav nav-pills flex-column border-right"
                    id="myTab"
                    role="tablist"
                  >
                    {this.state.masterUserDetails && !condition.includes('research') && <li className="nav-item">
                      <button
                        disabled={this.state.masterUserDetails.id == this.props.logged_userData.id}
                        id={this.state.masterUserDetails.id}
                        className={
                          (this.state.masterUserDetails.activeClass
                            ? "active"
                            : "") + " nav-link font-medium font-21 m-b-10 btn_bg"
                        }
                        onClick={() => this.getUserDetail(this.state.masterUserDetails, this.state.masterUserDetails.id)}
                      >
                        <span className="profile_img"><img src={
                          this.state.masterUserDetails.profilePic
                            ? constand.S3_API_IMG + '/profile/' + this.state.masterUserDetails.profilePic
                            : constand.WEB_IMAGES + "no-profile-pic.png"
                        } alt="" /></span>
                        <span className="profile_name">You</span>
                      </button>
                    </li>
                    }
                    {this.state.masterUserDetails && this.state.masterUserDetails.childUser && !condition.includes('research') && this.state.masterUserDetails.childUser.map((child_userData) => {
                      return (
                        <li className="nav-item">
                          <button
                            disabled={child_userData.id == this.props.logged_userData.id}
                            id={child_userData.id}
                            className={
                              (child_userData.activeClass
                                ? "active"
                                : "") + " nav-link font-medium font-21 m-b-10 btn_bg "
                            }
                            onClick={() => this.getUserDetail(child_userData, child_userData.id)}
                          >
                            <span className="profile_img">
                              <img
                                className="img-fluid rounded-circle"
                                src={
                                  child_userData.profilePic
                                    ? constand.S3_API_IMG + '/profile/' + child_userData.profilePic
                                    : constand.WEB_IMAGES + "no-profile-pic.png"
                                }
                              />
                            </span>
                            <span className="profile_name">{child_userData.name} {child_userData.lastName}</span>
                          </button>
                        </li>
                      )
                    })
                    }

                    {authData && authData.masterUserID == null && !condition.includes('research') &&
                      <li className="nav-item">
                        <button
                          className="nav-link font-medium font-21 m-b-30"
                          onClick={() => this.addNewChildAccount()}
                        >
                          <span className="profile_img plus_profile_img"><i className="fa fa-plus"></i></span>                      <span className="profile_name"></span>
                        </button>
                      </li>
                  /*
                  <li className="nav-item">
                    <a
                      className="nav-link font-medium font-21 m-b-10"
                      href="javascript:void(0)"
                      onClick={()=>this.tabChange(5)}
                    >
                      Delete Account
                    </a>
                  </li> */}
                  </ul>
                </div>
                }


                {/* profile section end */}
                <div className="col-md-6 col-lg-8">

                  {!this.state.Loading && <div>
                    {this.props.match.path === "/accounts/basicInformation" && (
                      <AccountBasicInfoComponent
                        countriesList={this.state.countriesList}
                        userData={this.props.logged_userData}
                        history={this.props.history}
                        checkAddProfileNavigation={check => this.setAddProfileNavigation = check}
                      />
                    )}
                    {this.props.match.path === "/accounts/myConditions" && (
                      <MyConditionComponent history={this.props.history} />
                    )}
                    {this.props.match.path === "/accounts/membership" && (
                      <AccountMembershipComponent
                        userData={this.props.logged_userData}
                      />
                    )}
                    {this.props.match.path === "/accounts/communicationPreferences" && (
                      <AccountCommunicationComponent
                        userData={this.props.logged_userData}
                      />
                    )}
                    {this.props.match.path === "/accounts/emergencyInfo" && (
                      <EmergencyInfoComponent
                        userData={this.props.logged_userData}
                        history={this.props.history}
                        checkAddProfileNavigation={check => this.setAddProfileNavigation = check}
                      />
                    )}
                    {this.props.match.path === "/accounts/movementprefs" && (
                      <MovementPrefsInfoComponent
                        history={this.props.history}
                        userData={this.props.logged_userData}
                      />
                    )}
                    {this.state.masterUserDetails && this.state.isConditionModalOpen &&
                      <SubProfileRegisterComponent
                        ismodel_open={this.state.isConditionModalOpen}
                        closeModel={this.closeModel}
                        masterUserName={this.state.masterUserDetails.name + ' ' + this.state.masterUserDetails.lastName}
                        masterUserId={this.state.masterUserDetails.id}
                        history={this.props.history}
                        registerFormValues={this.props.registerFormValues}
                        location={this.props.location}
                      />
                    }
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    logged_userData: state.header.logged_userData,
    close_banner: state.header.close_banner,
    close_banner_kd: state.header.close_banner_kd,
    master_user_details: state.header.master_user_details,
    healthcondition_list: state.register.healthcondition_list,
    init_condition: state.auth.initial_condition,
    registerFormValues: state.register.registerFormValues,
  };
};

const mapDispatchToProps = {
  fetchUserDetails,
  getCountriesList,
  start_loader,
  stop_loader,
  updateUserdataRedex,
  getRegionList,
  healthCondition,
  get_membership,
  impersonateAccount,
  setStep,
  setExploreConditions,
  clearRegisterFormUpdate,
  setAllUserList,
  registerformUpdate,
  setMasterUserId,
  fetchUserOtherDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetailComponent);
