import { GET_PAGE_DATA } from "../utilities";
let _ = require("lodash");

const INITIAL_STATE = {
  page_data: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PAGE_DATA:
      return {
        ...state,
        page_data: action.payload,
      };
    default:
      return state;
  }
};
