import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  setCondition,
  updateUserChallengeTag,
  loginModelOpen,
  cautionModalOpen,
  getAWSImages,
  getConditionBanner,
  getPageData2,
  updateWarningBanner,
} from "../../actions";
import * as constand from "../../constant";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import { Cookies } from "react-cookie-consent";
import CffBanner from "./CffBanner";
import { toast } from "react-toastify";
import { commonService } from "../../_services";
import CautionModal from "../Common/CautionModal";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import CommonForm from "./CommonForm";
import Carousel from "./Carousel";
import Testimonial from "./TestimonialSection";
import { RichText } from "prismic-reactjs";
//import { PrismicRichText } from "@prismicio/react";
import DOMPurify from "dompurify";
SwiperCore.use([Autoplay, Navigation]);
class HomePageTwo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoUrl: "https://vimeo.com/359842015",
      firstTime: 1,
      homePageLogos: [],
      pageDetails: {},
      hideImage:false
    };
    this.conditonClassList = [...constand.CONDITION_CLASS_LIST];
    this.returnClass = this.conditonClassList.reverse();
    this.swiperRef = React.createRef();
  }

  componentWillReceiveProps() {
    this.conditonClassList = [...constand.CONDITION_CLASS_LIST];
  }
  componentDidMount() {
    this.getHomePage();
    console.log("home-this.props", this.props);
    var beamChallenge = this.props.match
      ? this.props.match.params.beamchallenging &&
        this.props.match.params.beamchallenging.includes("keepbeaming-signup")
        ? "keepbeaming-signup"
        : ""
      : "";
    localStorage.setItem("beamchallenging", beamChallenge);
    if (localStorage.getItem("beamchallenging") && !this.props.is_auth) {
      console.log("test*********");
      this.props.loginModelOpen(true);
    }

    var isRestrictedPage =
      this.props.location && this.props.location.state
        ? this.props.location.state.isRestrictedPage
        : false;
    var isBeamRestrictedPage =
      this.props.location && this.props.location.state
        ? this.props.location.state.isBeamRestrictedPage
        : false;
    var isHiddenDataAccess =
      this.props.location && this.props.location.state
        ? this.props.location.state.isHiddenDataAccess
        : false;

    if (isRestrictedPage) {
      toast.error(constand.ERROR_RESTRICTED_PAGE);
    } else if (isBeamRestrictedPage) {
      toast.error(constand.ERROR_BEAM_RESTRICTED_PAGE);
    } else if (isHiddenDataAccess) {
      //show popup
      this.props.cautionModalOpen(true);
      const { state } = this.props.location;
      const stateCopy = { ...state };
      delete stateCopy.isHiddenDataAccess;
      this.props.history.replace({ state: stateCopy });
    }
    /* console.log('home-componentDidMount', localStorage.getItem('beamchallenging'));
    if (beamChallenge && this.props.is_auth) {
      console.log('home-updateUserChallengeTag')
     // this.props.updateUserChallengeTag();
    } */
    window.scrollTo(0, 0);
    this.getAWSImages();
  }

  getAWSImages = () => {
    let data = { folderName: "home" };
    this.props
      .getAWSImages(data)
      .then((res) => {
        if (res && res.data) {
          let awsImgObjects = commonService.getAWSImages(res);
          this.setState({ homePageLogos: awsImgObjects });
        }
      })
      .catch((e) => {});
  };

  conditionalGoto(condition, url) {
    this.props.setCondition(condition);
    let formatCondition = commonService.replaceChar(condition, false);
    Cookies.set("condition", formatCondition);
    this.props.getConditionBanner(
      commonService.replaceChar(formatCondition, true)
    );

    if (constand.HOME_CONDITION_LIST.indexOf(condition) >= 0) {
      const { from } = { from: { pathname: "/" + formatCondition } };
      this.props.history.push(from);
    } else if (constand.HOME_CF_YOUTH_CONDITION.indexOf(condition) >= 0) {
      const { from } = { from: { pathname: "/cf-youth" } };
      this.props.history.push(from);
    } else if (constand.HOME_KD_YOUTH_CONDITION.indexOf(condition) >= 0) {
      const { from } = { from: { pathname: "/kidney-youth" } };
      this.props.history.push(from);
    } else {
      const { from } = { from: { pathname: url } };
      this.props.history.push(from);
    }
  }

  getColorClass(key) {
    if (this.returnClass.length > 0) return this.returnClass[key];
  }

  returnPartnershipUrl = () => {
    var conditions =
      typeof Cookies.get("condition") != "undefined"
        ? Cookies.get("condition")
        : constand.KR_CONDITION;
    return !constand.NO_PARTNERSHIP_CONDITIONS.includes(conditions)
      ? "/partnerships/" + conditions
      : "partnerships/" + constand.KR_CONDITION;
  };

  handleSlideChange = () => {
    if (this.swiperRef.current) {
      this.swiperRef.current.swiper.autoplay.start();
    }
  };

  getHomePage() {
    this.setState({ Loading: true });
    this.props.getPageData2().then(
      (response) => {
        if (response) {
          const pageDetails = response.data.body;
          const categorizedSections = pageDetails.reduce((acc, section) => {
            const sliceType = section.slice_type;
            if (!acc[sliceType]) {
              acc[sliceType] = [];
            }
            acc[sliceType].push(section);
            return acc;
          }, {});
          const heroSections =
            categorizedSections["landing_page_hero_section"] || [];
          const sliderSections =
            categorizedSections["landing_page_slider"] || [];

          const firstColumnSection =
            categorizedSections["landing_page_4_column"] || [];

          const columnSections =
            firstColumnSection.length > 0 ? firstColumnSection[0] : [];

          const fColumnSection3 =
            categorizedSections["landing_page_3_column"] || [];

          const columnSections3 =
            fColumnSection3.length > 0 ? fColumnSection3[0] : [];

          const articleSections =
            categorizedSections["landing_page_articles"] || [];
          const testimonialSections =
            categorizedSections["landing_page_testimonials_slider"] || [];

          const topBar = categorizedSections["top_bar"] || [];

          const warningBannerData = topBar.length
            ? topBar.map((item) => {
                const text = item?.primary?.text?.[0]?.text || "-";
                const url = item?.primary?.link?.url || "#";
                return { text, url };
              })
            : [];

          this.props.updateWarningBanner(warningBannerData);

          const updatedPageDetails = {
            heroSections,
            sliderSections,
            columnSections,
            columnSections3,
            articleSections,
            testimonialSections,
          };
          this.setState({
            pageDetails: updatedPageDetails,
            Loading: false,
          });
        }
      },
      (error) => {
        console.error("Error fetching page data:", error);
        this.setState({ Loading: false });
      }
    );
  }

  handleHideImage = () => {
    this.setState({ hideImage: this.state.hideImage ? false : true });
  }
  render() {
    var currentPath = this.props.history
      ? this.props.history.location.pathname
      : "/home";
    let authData =
      JSON.parse(localStorage.getItem("userDetails")) ||
      this.props.logged_userData;
    let closeBannerCookie = Cookies.get("condition")
      ? "closeBanner" +
        commonService.replaceSpaceToChar(
          Cookies.get("condition").toLowerCase(),
          ""
        )
      : "";
    const listStyle = {
      maxWidth: "600px",
      paddingLeft: "10%",
    };

    const className = "list-unstyled text-left mx-auto font-qregular";
    const imageStyle = {
      maxWidth: "100%",
    };

    const fullWidthSectionStyle = {
      backgroundColor: "#ccc",
    };
    const {
      heroSections,
      sliderSections,
      columnSections,
      columnSections3,
      articleSections,
      testimonialSections,
    } = this.state.pageDetails;

    const sliderData = sliderSections?.[0];
    const sliderTitle = sliderData?.primary?.slider_title;
    const sliderOneItems = sliderData?.items;

    const testimonialData = testimonialSections?.[0];
    const testimonialTitle = testimonialData?.primary?.testimonials_title;
    const testimonialItems = testimonialData?.items;

    if (this.state.Loading) {
      return (
        <div className="preloader">
          <div className="spinner"></div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>
            {constand.HOME_TITLE}
            {constand.BEAM}
          </title>
          <meta
            property="og:title"
            content={constand.HOME_TITLE + constand.BEAM}
          />
          <meta property="og:description" content={constand.HOME_DESC} />
          <meta property="og:image" content={constand.HOME_PAGE_IMAGE} />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:site_name" content="Beam" />
          <meta
            name="twitter:image:alt"
            content={constand.HOME_PAGE_IMAGE_ALT}
          />
        </Helmet>
        <div
          className={
            (Cookies.get("closeBanner") == "false" &&
              !this.props.close_banner) ||
            (Cookies.get("closeBannerKD") == "false" &&
              !this.props.close_banner_kd)
              ? "homecontent"
              : "homecontent "
          }
        >
          {heroSections && heroSections.length > 0 && (
            <div className="container-fluid pt-lg-4 px-120 gradient-light home2-top position-relative mb-5">
              <div className="row">
                <div className="col-lg-6 my-lg-5 text-left pl-2">
                  <h2
                    className="font-gbold easy-to-follow text-center text-sm-left"
                    dangerouslySetInnerHTML={{
                      __html: heroSections[0].primary.hero_title[0]?.text || "",
                    }}
                  />
                  {heroSections[0].primary.hero_description && (
                    <div
                      className="hero-description"
                      dangerouslySetInnerHTML={{
                        __html:
                          heroSections[0].primary.hero_description[0]?.text ||
                          "",
                      }}
                    />
                  )}
                  {heroSections[0].primary.hero_cta_link?.url && (
                    <a
                      className="nav-link btn btn-custom ml-2 get-started font-gbold button-max-width"
                      href={heroSections[0].primary.hero_cta_link.url}
                    >
                      {heroSections[0].primary.hero_cta_text && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              heroSections[0].primary.hero_cta_text[0]?.text ||
                              "",
                          }}
                        />
                      )}
                    </a>
                  )}
                </div>
                <div className="col-lg-6 hero-right-col-img">
                  <img
                    src={
                      heroSections[0].primary.hero_image?.url ||
                      "static/media/highlight.png"
                    }
                    alt={
                      heroSections[0].primary.hero_image?.alt || "Video Classes"
                    }
                    style={{ maxWidth: "100%" }}
                  />
                  <div className="hero-right-company">*Greenwood et al, The Lancet, 2024</div>
                </div>
              </div>
              <div className="pills-color-center">
                <img src={constand.AWS_BADGE_IMAGE_URL + '/banner-pills.png'} alt="" className="img-fluid" />
              </div>
            </div>
          )}
          {(sliderTitle || sliderOneItems) && (
            <div className="container-fluid gradient-light">
              <h2 className="slider-title font-gbold">
                {sliderTitle && <RichText render={sliderTitle} />}
              </h2>
              {sliderOneItems && <Carousel items={sliderOneItems} />}
            </div>
          )}
          {(columnSections?.primary?.title || columnSections?.items) && (
            <div className="gradient-light">
              <div className="container my-5">
                <h2 className="section-title font-gbold mb-5">
                  {columnSections?.primary?.title && (
                    <RichText render={columnSections.primary.title} />
                  )}
                </h2>
                <div className="row text-center">
                  {columnSections?.items.map((item, index) => (
                    <div className="col-md-3" key={index}>
                      <div className="icon-wrapper">
                        <img
                          src={item.icon.url}
                          alt={item.icon.alt || "Icon"}
                        />
                      </div>
                      <h4 className="column-title">
                        {" "}
                        {item.title && <RichText render={item.title} />}
                      </h4>
                    </div>
                  ))}
                </div>
                {columnSections?.primary?.footer_text?.length > 0 ? (
                  <p className="evidence-text">
                    <RichText render={columnSections.primary.footer_text} />
                  </p>
                ) : (
                  <p className="evidence-text">
                  </p>
                )}
              </div>
            </div>
          )}
          {heroSections && heroSections.length > 0 && heroSections[1] && (
            <div className="gradient-light">
              <div className="container mt-5">
                <div className="row align-items-center">
                  <div className="col-md-5">
                    <div className="custom-video-wrapper" onClick={this.handleHideImage}>
                      <iframe
                      width="385"
                      height="475"
                      src="https://www.youtube.com/embed/appoXnFMzVY?si=wQ5mSHyBzrlFe82E?modestbranding=0&rel=0&autoplay=0&showinfo=0&controls=0"
                      frameborder="0"
                      allowfullscreen>
                      </iframe>
                      <img
                        onClick={this.handleHideImage}
                        src={
                          heroSections[1].primary.hero_image?.url ||
                          "static/media/video-preview.png"
                        }
                        alt={
                          heroSections[1].primary.hero_image?.alt || "Mrudula"
                        }
                        className={this.state.hideImage ? "img-fluid cover-image hide-cover" : "img-fluid cover-image"}
                      />
                    </div>
                  </div>
                  <div className="col-md-7 text-center text-sm-left">
                    {heroSections[1].primary.hero_title && (
                      <div
                        className="hero-description"
                        dangerouslySetInnerHTML={{
                          __html:
                            heroSections[1].primary.hero_title[0]?.text || "",
                        }}
                      />
                    )}
                    {heroSections[1].primary.hero_description && (
                      <div
                        className="hero-description pr-lg-5"
                        dangerouslySetInnerHTML={{
                          __html:
                            heroSections[1].primary.hero_description[0]?.text ||
                            "",
                        }}
                      />
                    )}
                    <img
                      src="static/media/beam.png"
                      alt="Illustration"
                      className="custom-illustration mb-10"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {columnSections3 && (
            <div
              className="ebc-section white-overlay"
              style={{
                backgroundImage: `url(${columnSections3?.primary?.background_image.url})`,
              }}
            >
              <div className="container-fluid px-150">
                <div className="text-center">
                  <h1 className="font-gbold easy-to-follow">
                    {columnSections3?.primary?.title && (
                      <RichText render={columnSections3.primary.title} />
                    )}
                  </h1>
                </div>
                {columnSections3?.items && (
                  <div className="row content-row">
                    {columnSections3?.items.map((item, index) => (
                      <div className="col-md-4 content-block">
                        <div className="ebc-card font-qregular">
                          <img
                            src={item.image.url}
                            alt={item.image.alt || "Icon"}
                          />
                          <h3
                            dangerouslySetInnerHTML={{
                              __html: item?.title[0]?.text || "",
                            }}
                          />
                          {/*  {item.title && <RichText render={item.title} />}
                        </h3> */}
                          {item.description && (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item?.description[0]?.text || "",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
          {heroSections && heroSections.length > 0 && heroSections[2] && (
            <div className="container section">
              <div className="row align-items-center">
                <div className="col-md-5 col-sm-12 col-xs-12 image-container position-relative">
                  <img
                    src={
                      heroSections[2].primary.hero_image?.url ||
                      "static/media/highlight3.png"
                    }
                    alt={
                      heroSections[2].primary.hero_image?.alt ||
                      "Phone showing Kidney Beam app"
                    }
                  />
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12 text-container text-center text-sm-left">
                  <h1
                    className="font-gbold easy-to-follow how-does"
                    dangerouslySetInnerHTML={{
                      __html: heroSections[2].primary.hero_title[0]?.text || "",
                    }}
                  />
                  <div
                    className="font-gregular mt-5 mb-5 text-left"
                    dangerouslySetInnerHTML={{
                      __html:
                        heroSections[2].primary.hero_description[0]?.text || "",
                    }}
                  ></div>
                  <a
                    className="btn-custom mt-10 get-started font-gbold button-bigger"
                    href={heroSections[2].primary.hero_cta_link?.url || "#"}
                  >
                    {heroSections[2].primary.hero_cta_text && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            heroSections[2].primary.hero_cta_text[0]?.text ||
                            "",
                        }}
                      />
                    )}
                  </a>
                </div>
              </div>
            </div>
          )}
          {heroSections && heroSections.length > 0 && heroSections[3] && (
            <div className="full-width-section blue">
              <div className="row">
                <div className="col-md-8 mx-auto">
                  <h1
                    className="font-gbold"
                    dangerouslySetInnerHTML={{
                      __html: heroSections[3].primary.hero_title[0]?.text || "",
                    }}
                  />
                  <p
                    className="font-gregular bluebody"
                    dangerouslySetInnerHTML={{
                      __html:
                        heroSections[3].primary.hero_description[0]?.text || "",
                    }}
                  ></p>
                  <a
                    className="mt-10 font-gbold text-secondary bluelink"
                    href={heroSections[3].primary.footer_link?.url || "#"}
                  >
                    {heroSections[3].primary.footer_text && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            heroSections[3].primary.footer_text[0]?.text || "",
                        }}
                      />
                    )}
                  </a>
                </div>
              </div>
            </div>
          )}
          {heroSections && heroSections.length > 0 && heroSections[4] && (
            <div className="gradient-light ">
              <div className="container my-5">
                <div className="row align-items-center">
                  <div className="col-md-12 text-center mb-5">
                    <h1
                      className="font-gbold big whycom"
                      dangerouslySetInnerHTML={{
                        __html:
                          heroSections[4].primary.hero_title[0]?.text || "",
                      }}
                    />
                  </div>
                  <div
                    className="col-md-8"
                    dangerouslySetInnerHTML={{
                      __html:
                        heroSections[4].primary.hero_description[0]?.text || "",
                    }}
                  ></div>

                  <div className="col-md-4 sm-center">
                    <img
                      src={
                        heroSections[4].primary.hero_image?.url ||
                        "static/media/screen1.png"
                      }
                      alt={
                        heroSections[4].primary.hero_image?.alt ||
                        "Kidney Beam Demo"
                      }
                      className="img-fluid custom-image"
                    />
                  </div>

                  <div className="col-md-12 text-center mt-5 mb-5">
                    <a
                      className="btn-custom mt-10 get-started font-gbold whycom-btn"
                      href={heroSections[4].primary.hero_cta_link?.url || "#"}
                    >
                      {heroSections[4].primary.hero_cta_text && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              heroSections[4].primary.hero_cta_text[0]?.text ||
                              "",
                          }}
                        />
                      )}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          {testimonialTitle && (
            <div className="testimonial-section gradient-light">
              <h1 className="font-gbold big">
                {testimonialTitle && <RichText render={testimonialTitle} />}
              </h1>
              {testimonialItems && <Testimonial items={testimonialItems} />}
            </div>
          )}
          {heroSections && heroSections.length > 0 && heroSections[5] && (
            <div
              className="full-width-section white-overlay"
              style={{
                ...fullWidthSectionStyle,
                backgroundImage: `url(${heroSections[5].primary.hero_image.url})`,
              }}
            >
              <div className="row">
                <div className="col-md-8 mx-auto">
                  <h1
                    className="full-width-section-title font-gbold"
                    dangerouslySetInnerHTML={{
                      __html: heroSections[5].primary.hero_title[0]?.text || "",
                    }}
                  />
                  <p
                    className="font-qregular full-width-section-description"
                    dangerouslySetInnerHTML={{
                      __html:
                        heroSections[5].primary.hero_description[0]?.text || "",
                    }}
                  ></p>
                  <a
                    className="btn-custom mt-10 get-started font-gbold"
                    href={heroSections[5].primary.hero_cta_link?.url || "#"}
                  >
                    {heroSections[5].primary.hero_cta_text && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            heroSections[5].primary.hero_cta_text[0]?.text ||
                            "",
                        }}
                      />
                    )}
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>

        <CautionModal
          title="Content not currently available"
          content="We're sorry but this content is no longer available at this time. Feel free to look around the site and see what else you might like. We're always here to support you so feel free to message our team for other suggestions at <a href='mailto:hello@beamfeelgood.com'>hello@beamfeelgood.com</a>"
          type="restricted_page_access"
          isConfirmation={false}
          isCloseRequired={false}
          hasButton={false}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    is_auth: state.auth.is_auth,
    IP_Details: state.accountinfo.ip_data,
    close_banner: state.header.close_banner,
    close_banner_kd: state.header.close_banner_kd,
    healthcondition_list: state.register.healthcondition_list,
    logged_userData: state.header.logged_userData,
  };
};

const mapDispatchToProps = {
  setCondition,
  updateUserChallengeTag,
  loginModelOpen,
  cautionModalOpen,
  getAWSImages,
  getConditionBanner,
  getPageData2,
  updateWarningBanner,
};
export default connect(mapStateToProps, mapDispatchToProps)(HomePageTwo);