import { combineReducers } from "redux";
import LoginReducers from "./LoginReducers";
import HeaderReducers from "./HeaderReducers";
import RegisterReducers from "./RegisterReducers";
import WorkoutReducers from "./WorkoutReducers";
import InstructorReducers from "./InstructorReducers";
import AccountReducers from "./AccountReducers";
import LiveClassReducers from "./LiveClassReducers";
import GroupReducers from "./GroupReducers";
import DashboardReducers from "./DashboardReducers";
import programmeReducer from "./programmeReducer";
import BlogReducers from "./BlogReducers";
import GoalReducers from "./GoalReducers";
import ProgrammeResourcesReducers from "./ProgrammeResourcesReducers";
import ProgrammeSurveyReducers from "./ProgrammeSurveyReducers";
import badgesReducer from './BadgeReducer';
import PageDataReducers from "./PageDataReducers";
export default combineReducers({
  auth: LoginReducers,
  header: HeaderReducers,
  register: RegisterReducers,
  workout: WorkoutReducers,
  programme: programmeReducer,
  instructor: InstructorReducers,
  accountinfo: AccountReducers,
  liveclass: LiveClassReducers,
  group: GroupReducers,
  dashboard: DashboardReducers,
  blogs: BlogReducers,
  goals: GoalReducers,
  programmeResources: ProgrammeResourcesReducers,
  ProgrammeSurvey: ProgrammeSurveyReducers,
  badges: badgesReducer,
  pageData:PageDataReducers
});
